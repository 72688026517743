import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import * as React from "react";
import SignUpFormControl from "./SignUpFormControl";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        signup: (props: SignUpFormProps) => {
            const spacing = props.spacing ?? 4;
            return {
                padding: theme.spacing(spacing),
                alignItems: "center",
                textAlign: "center",
            };
        },
        description: {
            margin: 0,
        },
        finePrint: {
            fontSize: "0.875rem",
            margin: 0,
        },
    })
);

interface SignUpFormProps {
    elevation?: number;
    spacing?: number;
    divider?: boolean;
}

const SignUpForm: React.FC<SignUpFormProps> = (props) => {
    const classes = useStyles(props);
    const elevation = props.divider ? 0 : props.elevation ?? 3;
    return (
        <React.Fragment>
            {props.divider && <Divider />}
            <Paper elevation={elevation}>
                <div className={classes.signup}>
                    <h2>Join me</h2>
                    <p className={classes.description}>
                        Get full articles delivered to your inbox.
                    </p>
                    <SignUpFormControl />
                    <p className={classes.finePrint}>
                        Form is protected by reCAPTCHA. Google's{" "}
                        <a href="https://policies.google.com/privacy">
                            Privacy
                        </a>{" "}
                        and{" "}
                        <a href="https://policies.google.com/terms">Terms</a>{" "}
                        apply.
                    </p>
                </div>
            </Paper>
        </React.Fragment>
    );
};

export default SignUpForm;
