import * as React from "react";
import { graphql } from "gatsby";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import Section from "../components/Section";
import SignUpForm from "../components/SignUpForm";
import Img, { FluidObject } from "gatsby-image";
import { MDXRenderer } from "gatsby-plugin-mdx";

interface BlogTemplateProps {
    data: PageQueryData;
}

interface PageQueryData {
    blogPost: {
        id: string;
        excerpt: string;
        body: string;
        slug: string;
        title: string;
        tags: string[];
        description: string;
        date: string;
        isoDate: string;
        image: {
            childImageSharp: {
                fluid: FluidObject;
            };
        };
        imageAlt: string;
    };
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& p": {
                marginBottom: 24,
            },
            "& h2": {
                marginTop: "1.25em",
            },
        },
        md: {
            marginTop: 16,
            "& figcaption": {
                fontSize: "1rem",
                color: theme.palette.grey[500],
                fontFamily: theme.typography.subtitle1.fontFamily,
            },
        },
    })
);

const BlogTemplate: React.FC<BlogTemplateProps> = (props) => {
    const classes = useStyles();
    const { blogPost } = props.data;
    const lineExcerpt = blogPost.excerpt.replace(/\n/g, " ");
    return (
        <Layout section="blog">
            <SEO
                title={blogPost.title}
                description={lineExcerpt}
                article
                path={blogPost.slug}
                date={blogPost.isoDate}
            />
            <Section content first>
                <article className={classes.root}>
                    <div>
                        <h1>{blogPost.title}</h1>
                        <Typography variant="subtitle1" component="span">
                            {blogPost.date}
                        </Typography>
                        <section className={classes.md}>
                            <MDXRenderer>{blogPost.body}</MDXRenderer>
                        </section>
                    </div>
                </article>
            </Section>
            <Section content>
                <SignUpForm divider />
            </Section>
        </Layout>
    );
};

export const pageQuery = graphql`
    query PostPageQuery($id: String!, $maxWidth: Int) {
        blogPost(id: { eq: $id }) {
            id
            excerpt
            body
            slug
            title
            tags
            description
            isoDate: date
            date(formatString: "MMMM DD, YYYY")
            image {
                childImageSharp {
                    fluid(maxWidth: $maxWidth) {
                        ...GatsbyImageSharpFluid
                        src
                    }
                }
            }
            imageAlt
            socialImage {
                childImageSharp {
                    fluid(maxWidth: $maxWidth) {
                        ...GatsbyImageSharpFluid
                        src
                    }
                }
            }
        }
    }
`;

export default BlogTemplate;
